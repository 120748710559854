import {defineStore} from 'pinia'
import {ref} from 'vue'

export const useJoinStore = defineStore('joinStore',
    () => {
        const alreadyJoined = ref(false)
        return {alreadyJoined};
    },
    {
        persist: true,
    },
);

export const useContactStore = defineStore('contactStore',
    () => {
        const alreadySent = ref(false)
        const name = ref('')
        const email = ref('')
        const phone = ref('')
        const job = ref('')
        const message = ref('')
        return {alreadySent, name, email, phone, job, message};
    },
    {
        persist: true,
    },
);


export const useHomePageStore = defineStore('homePageStore',
    () => {
        const products = [
            {
                id: 1,
                name: "Standard Pod",
                image: '/icons/products/standard.png',
                sliders: [
                    {
                        image: "/images/standard/1.jpg",
                    },
                    {
                        image: "/images/standard/2.jpg",
                    },
                    {
                        image: "/images/standard/3.jpg",
                    },
                    {
                        image: "/images/standard/1.jpg",
                    },
                    {
                        image: "/images/standard/2.jpg",
                    },
                    {
                        image: "/images/standard/3.jpg",
                    },
                ],
                link: "#",
                description: "The EvoNap Standard Pod offers a comfortable, ergonomic design with basic smart features, perfect for short, refreshing naps in busy environments. It includes privacy, soundproofing, and ambient controls.",
            },
            {
                id: 2,
                name: "Deluxe Pod",
                image: '/icons/products/deluxe.png',
                sliders: [
                    {
                        image: "/images/deluxe/1.jpg",
                    },
                    {
                        image: "/images/deluxe/2.jpg",
                    },
                    {
                        image: "/images/deluxe/3.jpg",
                    },
                    {
                        image: "/images/deluxe/1.jpg",
                    },
                    {
                        image: "/images/deluxe/2.jpg",
                    },
                    {
                        image: "/images/deluxe/3.jpg",
                    },
                ],
                link: "#",
                description: "The Deluxe Pod elevates comfort with premium materials, enhanced smart features, and integrated back and neck massagers. Ideal for extended rest, it provides the ultimate relaxation experience.",
            },
            {
                id: 3,
                name: "Compact Pod",
                image: '/icons/products/compact.png',
                sliders: [
                    {
                        image: "/images/compact/1.jpg",
                    },
                    {
                        image: "/images/compact/2.jpg",
                    },
                    {
                        image: "/images/compact/3.jpg",
                    },
                    {
                        image: "/images/compact/1.jpg",
                    },
                    {
                        image: "/images/compact/2.jpg",
                    },
                    {
                        image: "/images/compact/3.jpg",
                    },
                ],
                link: "#",
                description: "Designed for smaller spaces, the Compact Pod offers the same ergonomic design and essential smart features in a more space-efficient form. It’s perfect for tight office spaces or airports.",
            },
            {
                id: 4,
                name: "Custom Pod",
                image: '/icons/products/custom.png',
                sliders: [
                    {
                        image: "/images/custom/1.jpg",
                    },
                    {
                        image: "/images/custom/2.jpg",
                    },
                    {
                        image: "/images/custom/3.jpg",
                    },
                    {
                        image: "/images/custom/1.jpg",
                    },
                    {
                        image: "/images/custom/2.jpg",
                    },
                    {
                        image: "/images/custom/3.jpg",
                    },
                ],
                link: "#",
                description: "The Custom Pod allows businesses to tailor design, size, and features to their specific needs. Whether integrating additional wellness technology or customizing aesthetics, it fits unique environments.",
            },
        ];

        const productFeatures = [
            {
                title: "Ergonomic Design",
                body: "EvoNap napping pods are crafted for ultimate comfort, supporting optimal posture and relaxation. Designed with adjustable settings to suit every user's needs.",
                linkText: "Read more",
                linkHref: "#",
                image: "/images/product_5_1920x1080.jpg"
            },
            {
                title: "Smart Technology Integration",
                body: "Our pods feature advanced smart tech, including climate control, ambient lighting, and personalized wake-up systems, creating a tailored rest experience.",
                linkText: "Read more",
                linkHref: "#",
                image: "/images/product_6_1920x1080.jpg"
            },
            {
                title: "Privacy and Soundproofing",
                body: "Each pod offers a quiet, private space with soundproofing features, ensuring users can disconnect from distractions and recharge peacefully.",
                linkText: "Read more",
                linkHref: "#",
                image: "/images/product_7_1920x1080.jpg"
            },
            {
                title: "Health and Wellness Focus",
                body: "EvoNap promotes well-being by addressing fatigue, stress, and health issues, such as headaches, allowing users to return refreshed and energized.",
                linkText: "Read more",
                linkHref: "#",
                image: "/images/product_7_1920x1080.jpg"
            },
        ]
        return { products, productFeatures };
    },
);

export const useProductPageStore = defineStore('productPageStore',
    () => {
        const products = {
            'standard': {
                headerImages: [
                    '/images/product_standard_1.jpg',
                    '/images/product_standard_2.jpg',
                ],
                headerLight: false,
                headerTextFirst: 'Standard',
                headerTextSecond: 'Napping pod',
                title: 'Standard Pod',
                description:
                    'Crafted from a premium blend of wood, dimmable glass, and composite materials, the Standard Pod offers a modern and inviting space designed for relaxation and productivity. Its external dimensions of 220120160 cm, along with a 90*200 cm mattress, provide a cozy and private retreat for rest.\n' +
                    'With advanced sound insulation, the pod ensures that external noise is blocked, allowing you to enjoy uninterrupted sleep or listen to podcasts and music without sound escaping. The air inside the pod is continuously ventilated, complemented by a cooling system to maintain optimal comfort.\n' +
                    'The lighting inside the pod is fully adjustable, offering options for reading, relaxation, or complete darkness, tailored to your needs. For added convenience, the pod comes with a one-time-use hygiene pack, ensuring a fresh and clean experience every time.\n' +
                    'Equipped with a top-quality mattress and pillows, the pod guarantees restful sleep, while the dimmable glass and curtains offer complete privacy when needed.\n' +
                    'Enhance your experience with a built-in stereo sound system, featuring the option to play white noise or soothing instrumental music. Bluetooth connectivity allows you to stream your favorite music effortlessly. Additionally, the pod includes a hot water bag for added comfort.\n' +
                    'The Standard Pod is perfect for a wide range of environments, including offices, coworking spaces, innovation hubs, financial rooms, educational centers, and shopping malls. It’s the ideal solution for those seeking a peaceful space to recharge or focus, wherever you are.',
                usecases: [
                    {
                        id: 1,
                        title: "A Space to Recharge Energy on Busy Days",
                        text: "The Standard Pod is a sleek and efficient pod that offers a perfect space for rest. With proper ventilation, phone charging, an automatic sanitation system, and sound insulation, it allows you to recharge comfortably anytime and anywhere. These features make the Standard Pod ideal for busy locations like offices, retail stores, airports, cafes...",
                        image: "/images/usecases/1.jpg"
                    },
                    {
                        id: 2,
                        title: "A Relaxing Experience in a Modern Design",
                        text: "The Standard Pod is crafted from a premium blend of wood and dimmable glass, offering a modern and comfortable space for relaxation. With dimensions of 220×120×160 cm and a 90×200 cm mattress, it is ideal for offices, educational centers, and stores. Advanced sound insulation and continuous ventilation ensure tranquility during your rest.",
                        image: "/images/usecases/2.jpg"
                    },
                ],
                features: [
                    {
                        id: 1,
                        title: "Ergonomic comfort",
                        text: "Tailored to fit your body perfectly",
                        image: "/images/standard/ergonomic_comfort.jpg"
                    },
                    {
                        id: 2,
                        title: "Soundproofing",
                        text: "Enjoy peace by blocking all external noise.",
                        image: "/images/standard/sound_proofing.jpg"
                    },
                    {
                        id: 3,
                        title: "Ambient Lighting",
                        text: "Set the mood with adjustable lighting.",
                        image: "/images/standard/ambient_lighting.jpg"
                    },
                    {
                        id: 4,
                        title: "Climate Control",
                        text: "Achieve comfort with precise temperature control.",
                        image: "/images/standard/climate_control.jpg"
                    },
                ]
            },
            'compact': {
                headerImages: [
                    '/images/product_compact_1.jpg',
                    '/images/product_compact_2.jpg',
                ],
                headerLight: false,
                headerTextFirst: 'Compact',
                headerTextSecond: 'Napping pod',
                title: 'Compact Pod',
                description: 'The Compact Model is a smart and efficient product designed with ergonomic principles, perfectly suited for small spaces. Featuring a medical-grade, ergonomic mattress, it ensures comfort and support for your body while maximizing space efficiency.\n' +
                    'The Compact is the perfect choice for those seeking a practical and stylish solution to optimize space without compromising on comfort.',
                usecases: [
                    {
                        id: 1,
                        title: "A Space to Recharge Energy on Busy Days",
                        text: "The Delux Pod is a luxurious and stylish pod that offers an exceptional resting experience. With features like climate control, phone charging, an automatic sanitation system, and an attractive design, this product is an ideal choice for busy locations such as offices, retail stores, airports, and cafes.",
                        image: "/images/usecases/1.jpg"
                    },
                    {
                        id: 2,
                        title: "A Relaxing Experience in a Modern Design",
                        text: "The Deluxe Pod combines a sophisticated design with wood and dimmable glass, creating a luxurious space for relaxation. With dimensions of 220×120×160 cm and a 90×200 cm mattress, it is perfect for offices, innovation hubs, and shopping centers. Advanced sound insulation and continuous ventilation ensure a peaceful environment during your rest.",
                        image: "/images/usecases/2.jpg"
                    },
                ],
                features: [
                    {
                        id: 1,
                        title: "Space-saving Design",
                        text: "Enjoy efficiency with a design that fits anywhere.",
                        image: "/images/compact/space_saving_design.jpg"
                    },
                    {
                        id: 2,
                        title: "Soundproofing",
                        text: "Block out distractions for a peaceful and serene experience.",
                        image: "/images/compact/sound_proofing.jpg"
                    },
                    {
                        id: 3,
                        title: "Ambient Control",
                        text: "Customize your space for perfect comfort and ambiance.",
                        image: "/images/compact/ambient_control.jpg"
                    },
                ]
            },
            'deluxe': {
                headerImages: [
                    '/images/product_deluxe_1.jpg',
                    '/images/product_deluxe_2.jpg',
                ],
                headerLight: true,
                headerTextFirst: 'Deluxe',
                headerTextSecond: 'Napping pod',
                title: 'Deluxe Pod',
                description: 'The Deluxe Model offers a sophisticated design, combining wood, dimmable glass, and composite materials to create a luxurious and functional space. With external dimensions of 220120160 cm and a 90*200 cm mattress, it provides a comfortable environment for restful sleep.\n' +
                    'This premium pod is equipped with sound insulation to ensure complete peace and quiet, allowing you to enjoy undisturbed sleep or listen to podcasts and music without any external noise or sound leakage. The internal air is continuously ventilated, and the cooling system ensures optimal temperature control.\n' +
                    'The adjustable lighting within the pod can be customized to suit your needs, whether it\'s for reading, sleeping, or complete darkness. For added hygiene, the pod includes a UV lamp for disinfection and a one-time-use hygiene pack.\n' +
                    'Designed for maximum comfort, the pod features an ultra-comfortable mattress, orthopedic pillows, a neck and back massager, a hot water bag, and an eye pillow for additional relaxation. The dimmable glass and curtains provide complete privacy when needed.\n' +
                    'Additionally, the Deluxe pod is equipped with a high-quality stereo sound system, allowing you to play white noise or instrumental music for a calming atmosphere. You can also stream your favorite music via Bluetooth.\n' +
                    'Perfectly suited for a range of environments including offices, coworking spaces, innovation hubs, financial and banking rooms, educational centers, and shopping malls, the Deluxe offers an exceptional blend of comfort, privacy, and advanced features to elevate any workspace or relaxation area.',
                usecases: [
                    {
                        id: 1,
                        title: "A Space to Recharge Energy on Busy Days",
                        text: "The Compact Pod is a wall-mounted pod specifically designed for small spaces. With an affordable price and minimal footprint, it is an excellent choice for companies and locations that want to keep costs down. Although this product lacks sound insulation, it provides good features for resting, allowing you to find tranquility in busy environments.",
                        image: "/images/usecases/1.jpg"
                    },
                    {
                        id: 2,
                        title: "A Relaxing Experience in a Modern Design",
                        text: "The Compact Pod is an efficient product with ergonomic design, perfectly suited for small spaces. With a medical-grade ergonomic mattress, it offers the necessary comfort and is ideal for small areas like hallways, dining rooms, office spaces, and shared environments. The Compact Pod is an excellent choice for optimizing space without compromising comfort.",
                        image: "/images/usecases/2.jpg"
                    },
                ],
                features: [
                    {
                        id: 1,
                        title: "Luxury materials",
                        text: "Experience elegance with high-quality materials.",
                        image: "/images/deluxe/luxury_materials.jpg"
                    },
                    {
                        id: 2,
                        title: "Smart Tech Integration",
                        text: "Control your environment effortlessly with smart features.",
                        image: "/images/deluxe/smart_tech_integration.jpg"
                    },
                    {
                        id: 3,
                        title: "Massager",
                        text: "Relieve tension with personalized massage experiences.",
                        image: "/images/deluxe/massager.jpg"
                    },
                ]
            },
            'custom': {
                headerImages: [
                    '/images/product_custom_3.jpg',
                    '/images/product_custom_4.jpg',
                ],
                headerLight: false,
                headerTextFirst: 'Custom',
                headerTextSecond: 'Napping pod',
                title: 'Custom Pod',
                description: 'The Custom Pod is designed to be fully customizable, offering flexibility to suit your unique needs. Every feature and option can be added or removed based on your preferences, ensuring a personalized experience that aligns perfectly with your vision.\n' +
                    'This model is ideal for large organizations looking to enhance their branding presence, whether it\'s through public campaigns or creating a distinctive, branded atmosphere within their corporate spaces. With the Custom Pod, your brand can shine through every detail, providing a truly unique and impactful experience.',
                usecases: [
                    {
                        id: 1,
                        title: "A Space to Recharge Energy on Busy Days",
                        text: "The Custom Pod is a customizable pod that allows organizations and businesses to specify features and designs according to their unique needs. This product can include various colors, logos, and special amenities, helping locations like offices, commercial centers, airports, and cafes create a unique and comfortable space.",
                        image: "/images/usecases/1.jpg"
                    },
                    {
                        id: 2,
                        title: "A Relaxing Experience in a Modern Design",
                        text: "The Custom Pod is fully customizable, allowing you to adjust features and options to suit your unique needs. This model is ideal for large organizations, hotels, and public spaces looking to enhance their branding presence and create a distinctive atmosphere.",
                        image: "/images/usecases/2.jpg"
                    },
                ],
                features: [
                    {
                        id: 1,
                        title: "Full Customization",
                        text: "Tailor your experience to fit your needs.",
                        image: "/images/custom/full_customization.jpg"
                    },
                    {
                        id: 2,
                        title: "Smart Tech Integration",
                        text: "Effortless relaxation with smart tech.",
                        image: "/images/custom/smart_tech_integration.jpg"
                    },
                    {
                        id: 3,
                        title: "Premium Comfort",
                        text: "Experience luxurious comfort in every moment.",
                        image: "/images/custom/premium_comfort.jpg"
                    },
                ]
            },
        };
        return { products }
    }
);
